// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact {
    display: flex;
    padding: 5vh 0vw 5vh 5vw;
    background-color: aliceblue;
    align-items: center;
    font-family: "dosis", sans-serif;
    transition: 0.5s;
}

.Contact:hover {
    background-color: rgb(226, 241, 255);
}

Section p,
h1 {
    margin: 5px;
}

.Contact-image {
    display: flex;
    width: 400px;
    height: auto;
    justify-content: center;
    align-items: center;
}

.Contact-content {
    font-size: 20px;
    padding: 0vh 5vw;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .Contact {
        flex-direction: column;
        padding: 5vh 0vw 5vh 0vw;
    }

    .Contact-content {
        font-size: 15px;
    }

    .Contact-image {
        width: 300px;
    }

}

.contact-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.contact-link {
    color: #0077cc;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #0077cc;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.contact-link:hover {
    background-color: #0077cc;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,2BAA2B;IAC3B,mBAAmB;IACnB,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,sBAAsB;QACtB,wBAAwB;IAC5B;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;;AAEJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".Contact {\n    display: flex;\n    padding: 5vh 0vw 5vh 5vw;\n    background-color: aliceblue;\n    align-items: center;\n    font-family: \"dosis\", sans-serif;\n    transition: 0.5s;\n}\n\n.Contact:hover {\n    background-color: rgb(226, 241, 255);\n}\n\nSection p,\nh1 {\n    margin: 5px;\n}\n\n.Contact-image {\n    display: flex;\n    width: 400px;\n    height: auto;\n    justify-content: center;\n    align-items: center;\n}\n\n.Contact-content {\n    font-size: 20px;\n    padding: 0vh 5vw;\n    text-align: left;\n}\n\n@media screen and (max-width: 768px) {\n    .Contact {\n        flex-direction: column;\n        padding: 5vh 0vw 5vh 0vw;\n    }\n\n    .Contact-content {\n        font-size: 15px;\n    }\n\n    .Contact-image {\n        width: 300px;\n    }\n\n}\n\n.contact-links {\n    display: flex;\n    justify-content: center;\n    gap: 20px;\n    margin-bottom: 20px;\n    margin-top: 20px;\n}\n\n.contact-link {\n    color: #0077cc;\n    text-decoration: none;\n    padding: 5px 10px;\n    border: 1px solid #0077cc;\n    border-radius: 5px;\n    transition: all 0.3s ease;\n}\n\n.contact-link:hover {\n    background-color: #0077cc;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
