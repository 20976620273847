// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer{
  padding: 20px 20px;
  background-color: rgb(242, 240, 240);
  font-family: "dosis", sans-serif;
}

.Footer-logos{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  text-align: center;
}

.Footer-logo {
  height: 150px;
  width: 150px;
}

.Footer-logo-bits{
  height: 200px;
  width: auto;
}

.Footer-contact {
  display: flex;
  margin: 5px 0;
  justify-content: space-evenly;
  padding: 0 20px;
}

.Footer-hr{
  margin: 10px;
}

.Footer-h2{
  margin: 10px;
  color: rgb(32, 30, 114);
}

@media screen and (max-width: 768px) {
  .Footer {
    padding: 5vh 0vw 5vh 0vw;
  }
  
  .Footer-logos {
    padding: 0 20px;
  }
  
  .Footer-contact {
    padding: 0 20px;
  }
  
  .Footer-logo {
    height: 150px;
    width: 150px;
  }
  
  .Footer-logo-bits {
    height: 180px;
    width: auto;
  }
  
  .Footer-h2 {
    display: none;
  }
  
  .Footer-hr {
    margin: 5px;
  }
  
}

`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,YAAY;EACd;;EAEA;IACE,aAAa;IACb,WAAW;EACb;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;AAEF","sourcesContent":[".Footer{\n  padding: 20px 20px;\n  background-color: rgb(242, 240, 240);\n  font-family: \"dosis\", sans-serif;\n}\n\n.Footer-logos{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n  text-align: center;\n}\n\n.Footer-logo {\n  height: 150px;\n  width: 150px;\n}\n\n.Footer-logo-bits{\n  height: 200px;\n  width: auto;\n}\n\n.Footer-contact {\n  display: flex;\n  margin: 5px 0;\n  justify-content: space-evenly;\n  padding: 0 20px;\n}\n\n.Footer-hr{\n  margin: 10px;\n}\n\n.Footer-h2{\n  margin: 10px;\n  color: rgb(32, 30, 114);\n}\n\n@media screen and (max-width: 768px) {\n  .Footer {\n    padding: 5vh 0vw 5vh 0vw;\n  }\n  \n  .Footer-logos {\n    padding: 0 20px;\n  }\n  \n  .Footer-contact {\n    padding: 0 20px;\n  }\n  \n  .Footer-logo {\n    height: 150px;\n    width: 150px;\n  }\n  \n  .Footer-logo-bits {\n    height: 180px;\n    width: auto;\n  }\n  \n  .Footer-h2 {\n    display: none;\n  }\n  \n  .Footer-hr {\n    margin: 5px;\n  }\n  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
