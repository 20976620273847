// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Training{
    background-color: aliceblue;
    font-family: "dosis", sans-serif;
    padding: 1vh 10vw;
    font-size: 20px;
    transition: 0.5s;
}

.Training:hover{
    background-color: rgb(226, 241, 255);
}

.Training-content{
    text-align: left;
}

ul,
h1{
    margin: 0px;
}

.Training-technologies-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 2vh 5vw;
    justify-content: center;
    font-family: "dosis", sans-serif;
}

.Training-technology-item{
    padding: 10px 0px;
}

@media screen and (max-width: 768px) {
    .Training {
      flex-direction: column;
      padding: 5vh 0vw 5vh 0vw;
    }
    
    .Training-content {
      font-size: 15px;
    }
    
    .Training-technologies-grid {
      grid-template-columns: 1fr 1fr;
    } 
}`, "",{"version":3,"sources":["webpack://./src/components/Training.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,gCAAgC;IAChC,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,0CAA0C;IAC1C,SAAS;IACT,gBAAgB;IAChB,uBAAuB;IACvB,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;MACE,sBAAsB;MACtB,wBAAwB;IAC1B;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,8BAA8B;IAChC;AACJ","sourcesContent":[".Training{\n    background-color: aliceblue;\n    font-family: \"dosis\", sans-serif;\n    padding: 1vh 10vw;\n    font-size: 20px;\n    transition: 0.5s;\n}\n\n.Training:hover{\n    background-color: rgb(226, 241, 255);\n}\n\n.Training-content{\n    text-align: left;\n}\n\nul,\nh1{\n    margin: 0px;\n}\n\n.Training-technologies-grid{\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    gap: 1rem;\n    padding: 2vh 5vw;\n    justify-content: center;\n    font-family: \"dosis\", sans-serif;\n}\n\n.Training-technology-item{\n    padding: 10px 0px;\n}\n\n@media screen and (max-width: 768px) {\n    .Training {\n      flex-direction: column;\n      padding: 5vh 0vw 5vh 0vw;\n    }\n    \n    .Training-content {\n      font-size: 15px;\n    }\n    \n    .Training-technologies-grid {\n      grid-template-columns: 1fr 1fr;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
