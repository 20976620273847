// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background-color: rgb(255, 255, 252);
}

.App-header {
  background-color: #282c34;
  color: white;
}

.App-image {
  width: 100%;
  max-width: 100px;
  height: auto;
}

html{
  margin-top: 80px;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".App {\n  text-align: center;\n  background-color: rgb(255, 255, 252);\n}\n\n.App-header {\n  background-color: #282c34;\n  color: white;\n}\n\n.App-image {\n  width: 100%;\n  max-width: 100px;\n  height: auto;\n}\n\nhtml{\n  margin-top: 80px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
