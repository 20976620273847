// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About {
  display: flex;
  padding: 5vh 0vw 5vh 5vw;
  background-color: aliceblue;
  justify-content: space-around;
  align-items: center;
  font-family: "dosis", sans-serif;
  margin: 0px;
  transition: 0.5s;
}

.About:hover{
  background-color: rgb(226, 241, 255);
}

Section p,h1{
  margin: 5px;
}

.About-image{
  display: flex;
  width: 300px;
  height: auto;
  justify-content: center;
  align-items: center;
}

.About-content{
  font-size: 20px;
  padding: 0vh 5vw;
  text-align: left;
}

.Section-2 {
  display: flex;
  padding: 5vh 5vw 5vh 0vw;
  background-color: aliceblue;
  justify-content: space-around;
  align-items: center;
  font-family: "dosis", sans-serif;
  transition: 0.5s;
}

.Section-2:hover{
  background-color: rgb(226, 241, 255);
}


.Section-2-image{
  display: flex;
  width: 300px;
  height: auto;
  justify-content: center;
  align-items: center;
}

.Section-2-content{
  font-size: 20px;
  padding: 0vh 5vw;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .About {
    flex-direction: column;
    padding: 5vh 0vw 5vh 0vw;
  }

  
  .Section-2 {
    flex-direction: column-reverse;
    padding: 5vh 0vw 5vh 0vw;
  }
  

}
`, "",{"version":3,"sources":["webpack://./src/components/About.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,2BAA2B;EAC3B,6BAA6B;EAC7B,mBAAmB;EACnB,gCAAgC;EAChC,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,2BAA2B;EAC3B,6BAA6B;EAC7B,mBAAmB;EACnB,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;;AAGA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;IACtB,wBAAwB;EAC1B;;;EAGA;IACE,8BAA8B;IAC9B,wBAAwB;EAC1B;;;AAGF","sourcesContent":[".About {\n  display: flex;\n  padding: 5vh 0vw 5vh 5vw;\n  background-color: aliceblue;\n  justify-content: space-around;\n  align-items: center;\n  font-family: \"dosis\", sans-serif;\n  margin: 0px;\n  transition: 0.5s;\n}\n\n.About:hover{\n  background-color: rgb(226, 241, 255);\n}\n\nSection p,h1{\n  margin: 5px;\n}\n\n.About-image{\n  display: flex;\n  width: 300px;\n  height: auto;\n  justify-content: center;\n  align-items: center;\n}\n\n.About-content{\n  font-size: 20px;\n  padding: 0vh 5vw;\n  text-align: left;\n}\n\n.Section-2 {\n  display: flex;\n  padding: 5vh 5vw 5vh 0vw;\n  background-color: aliceblue;\n  justify-content: space-around;\n  align-items: center;\n  font-family: \"dosis\", sans-serif;\n  transition: 0.5s;\n}\n\n.Section-2:hover{\n  background-color: rgb(226, 241, 255);\n}\n\n\n.Section-2-image{\n  display: flex;\n  width: 300px;\n  height: auto;\n  justify-content: center;\n  align-items: center;\n}\n\n.Section-2-content{\n  font-size: 20px;\n  padding: 0vh 5vw;\n  text-align: left;\n}\n\n@media screen and (max-width: 768px) {\n  .About {\n    flex-direction: column;\n    padding: 5vh 0vw 5vh 0vw;\n  }\n\n  \n  .Section-2 {\n    flex-direction: column-reverse;\n    padding: 5vh 0vw 5vh 0vw;\n  }\n  \n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
